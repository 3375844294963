<template>
  <a-modal v-model="visible" width="350px" centered>
    <!-- 버튼 -->
    <template slot="footer">
      <a-button key="btn" type="primary" @click="confirm">확인</a-button>
    </template>

    <div>
      <h2>인증해주세요</h2>
      <div>
        <a-list :split="false">
          <a-list-item>
            <a-col :span="14">* 휴대폰번호 뒷 4자리</a-col>
            <a-col :span="10">
              <a-input centered ref="refCell" v-model="cell" />
            </a-col>
          </a-list-item>
          <a-list-item v-if="alertText !== ''">
            <a-col :span="24" class="alertText">{{ alertText }}</a-col>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    alertText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      cell: process.env.NODE_ENV === 'development' ? '3230' : '',
    };
  },
  methods: {
    _showModal() {
      this.visible = true;

      setTimeout(() => {
        this.$refs.refCell.focus();
      }, 500);
    },
    confirm() {
      this.visible = false;
      this.$emit('completeInput', this.cell);
    },
  },
};
</script>
<style lang="scss">
.alertText {
  color: red;
}
</style>